<template>
  <div id="app">
    <div id="maincontainer">

      <div id="topsection">
        <banner :logo="config.logo" />
      </div>

      <div id="contentwrapper">
        <div id="contentcolumn">
          <div class="innertube">
            <router-view :topic="topic" :registered="registered" />
          </div>
        </div>
      </div>

      <div id="rightcolumn">
        <div class="innertube">
          <simanim v-if="registered" />
        </div>
      </div>

      <div id="footer">
        &copy;2008 - 2023 Rabbi Dovid Lipman. All rights reserved
        <br />FavIcon.ico by <a href="https://freeicons.io/profile/3997">fengquan li</a> on <a href="https://freeicons.io">freeicons.io</a>
      </div>

    </div>
  </div>
</template>

<script>
import Banner from './components/Banner.vue'
import Simanim from './components/Simanim.vue'
import { mapState } from 'vuex'

const descriptionPrefix = `HALACHA SOURCES: A Study Guide in English for Tracing the Halacha

In the footsteps of the authors of the Shulchan Aruch and the other authorities, including many contemporary rulings

by Rabbi Dovid Lipman

`
const chanukahDescription = descriptionPrefix + `Introductory Volume - The Halachos of Chanukah (Shulchan Aruch Orach Chayim 670 through 684)

Pre-release Edition`
const birkasHachamahDescription = descriptionPrefix + `Introductory Volume - The Halachos of Birkas HaChamah (Shulchan Aruch Orach Chayim 229)

Pre-release Edition`

const configs = {
  birkas_hachamah: {
			logo: "images/sunrise.jpg",
			title: "LearnHalacha.com - Halachos of Birkas HaChamah - Special Edition",
			topic_text: "Birkas HaChamah",
			meta: [
      {
        name: 'keywords',
        content: 'Birkas HaChamah, Birchas HaChamah, Blessing of the Sun, Tekufah, solar year, Halacha, Bais Yosef, Beis Yosef, Bet Yoseph, Beit Yoseph, Shulchan Aruch, Mishna Berurah, Gemara, Rishonim, Achronim, Poskim, Halachic, Hallel, Responsa, Rabbinic'
      },
      {
        name: 'description',
        content: birkasHachamahDescription
      }
    ]
  },
	chanukah: {
			logo: "images/learnhalacha.jpg",
			title: "LearnHalacha.com - Hilchos Chanukah - Pre-release",
			topic_text: "Chanukah",
			meta: [
        {
          name: 'keywords',
          content: 'Chanuka, Chanukah, Hanukah, Hanuka, Menorah, Menora, Candles, Halacha, Bais Yosef, Beis Yosef, Bet Yoseph, Beit Yoseph, Shulchan Aruch, Mishna Berurah, Gemara, Rishonim, Achronim, Poskim, Halachic, Hallel, Responsa, Rabbinic'
        },
        {
          name: 'description',
          content: chanukahDescription
        }
      ],
			sample: "sample_chanukah.php"
  }
}
const titledRoutes = {
  about: 'LearnHalacha.com - about Halacha Sources',
  dovidlipman: 'LearnHalacha.com - Rabbi Dovid Lipman'
}
export default {
  name: 'app',
  components: {
    Simanim,
    Banner
  },
  computed: {
    config () {
      return configs[this.$store.state.topic] // not sure if can use computed prop here
    },
    ...mapState(['topic', 'registered'])
  },
  metaInfo () {
    const routeName = this.$route.name
    return { 
      title: (routeName in titledRoutes) ? titledRoutes[routeName] : this.config.title,
      meta: this.config.meta
    }
  }
}
</script>

<style>
BODY {
	PADDING-RIGHT: 0px; BACKGROUND-POSITION: right 50%; PADDING-LEFT: 0px; FONT-SIZE: 14px; PADDING-BOTTOM: 0px; MARGIN: 0px; PADDING-TOP: 0px; BACKGROUND-REPEAT: repeat-y; FONT-FAMILY: arial
}
#topsection {
	BACKGROUND: #420d0b; HEIGHT: 90px
}
#topsection H1 {
	MARGIN: 0px; PADDING-TOP: 15px
}
#contentwrapper {
	FLOAT: left; WIDTH: 100%
}
#contentcolumn {
	MARGIN-RIGHT: 300px
}
#rightcolumn {
	BACKGROUND: #f0dab4; FLOAT: left; MARGIN-LEFT: -300px; WIDTH: 300px
}
#footer {
	CLEAR: left; PADDING-RIGHT: 0px; PADDING-LEFT: 0px; BACKGROUND: #420d0b; PADDING-BOTTOM: 4px; WIDTH: 100%; COLOR: #ffffff; PADDING-TOP: 4px; TEXT-ALIGN: center
}
#footer a:visited {
  color: lightgreen;
}
#footer a:hover {
  color: hotpink;
}
/* unvisited link */
#footer a:link {
  color: lightblue;
}
.innertube {
	MARGIN: 20px
}
H1 {
	FONT-WEIGHT: bold; FONT-SIZE: 24px; TEXT-TRANSFORM: uppercase; COLOR: #333366; LETTER-SPACING: -0.05em
}
H2 {
	FONT-SIZE: 18px; COLOR: #420d0b
}
H3 {
	FONT-WEIGHT: bold; FONT-SIZE: 16px; TEXT-TRANSFORM: uppercase; COLOR: #420d0b
}
A {
	COLOR: #333366; TEXT-DECORATION: none
}
A:link {
	TEXT-DECORATION: none
}
A:hover {
	COLOR: #420d0b; TEXT-DECORATION: underline
}
A:active {
	TEXT-DECORATION: underline
}
A.navbar {
	PADDING-RIGHT: 2px; PADDING-LEFT: 2px; FONT-WEIGHT: bold; FONT-SIZE: 12px; PADDING-BOTTOM: 2px; COLOR: #420d0b; LINE-HEIGHT: 14px; PADDING-TOP: 2px; FONT-FAMILY: Arial, Helvetica, sans-serif; BACKGROUND-COLOR: #f0dab4; TEXT-DECORATION: none
}
A.navbar:hover {
	PADDING-RIGHT: 2px; PADDING-LEFT: 2px; FONT-WEIGHT: bold; FONT-SIZE: 12px; PADDING-BOTTOM: 2px; COLOR: #ffffff; LINE-HEIGHT: 14px; PADDING-TOP: 2px; FONT-FAMILY: Arial, Helvetica, sans-serif; BACKGROUND-COLOR: #420d0b; TEXT-DECORATION: none
}
.tableheader {
	FONT-WEIGHT: bold; FONT-SIZE: 14px; COLOR: #ffffff; FONT-FAMILY: Arial, Helvetica, sans-serif; BACKGROUND-COLOR: #420d0b
}
A.menu {
	PADDING-RIGHT: 20px; DISPLAY: block; PADDING-LEFT: 20px; FONT-WEIGHT: bold; FONT-SIZE: 12px; COLOR: #ffffff; LINE-HEIGHT: 14px; FONT-FAMILY: Arial, Helvetica, sans-serif; BACKGROUND-COLOR: #333366; TEXT-ALIGN: center; TEXT-DECORATION: none
}
A.menu:hover {
	PADDING-RIGHT: 20px; DISPLAY: block; PADDING-LEFT: 20px; FONT-WEIGHT: bold; FONT-SIZE: 12px; COLOR: #ffffff; LINE-HEIGHT: 14px; FONT-FAMILY: Arial, Helvetica, sans-serif; BACKGROUND-COLOR: #420d0b; TEXT-ALIGN: center; TEXT-DECORATION: none
}
.empty_space {
	HEIGHT: 600px
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
