<template>
  <div class="links">
    <p><a href="http://www.shulchanarach.com/" target="_blank"><strong>ShulchanArach.com: Free English Translation of [portions of] The Shulchan Aruch</strong></a></p>
    <p><a href="http://www.TheShmuz.com" target="_blank"><strong>Online Torah Shiur - Jewish approach to life</strong><br> 
    Online Torah Shiur dealing with contemporary Jewish issues from a Mussar and Hashkafa perspective. A Jewish approach to life drawn from Torah sources, Chumash, Rashi, Navi, Mefarshim and Mussar Sefarim.</a></p>
    <p>  <a href="http://www.eparsha.com/" target="_blank"><strong>Eparsha.com</strong><br>
      The Electronic Torah Warehouse</a></p>
    <p>  <a href="http://www.torahanytime.com/" target="_blank"><strong>TorahAnytime</strong></a></p>
    <p><a href="http://www.naaleh.com/" target="_blank"><strong>Naaleh.com</strong><br />FREE Online Torah Video Classes. Streaming video, MP3 and Ipod Video downloads</a></p>
    <p><a href="http://webyeshiva.org/" target="_blank"><strong>WebYeshiva</strong><br />Rabbi Brovender's online yeshiva</a></p>
    <br />
    <p><strong>BIRKAS HACHAMAH LINKS</strong></p>
    <p><a target="_blank" href="http://www.chabad.org/library/article_cdo/aid/817861/jewish/Birkat-Hachamah.htm">www.chabad.org/library/article_cdo/aid/817861/jewish/Birkat-Hachamah.htm</a></p>
    <p><a target="_blank" href="http://rechovot.blogspot.com/2008/04/birkat-hachamahblessing-sun-2009-erev.html">rechovot.blogspot.com/2008/04/birkat-hachamahblessing-sun-2009-erev.html</a></p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Links'
}
</script>
