<template>
  <div class="author">
    <h1>Rabbi Dovid Lipman</h1>
    <p> Rabbi Dovid Lipman was born and raised in the community of Silver Spring, Maryland, in the United States. He received his Bachelor's in Talmudic Law from Ner Israel Rabbinical College in Baltimore, Maryland, and his rabbinic ordination from Rabbi Zalman Nechemiah Goldberg, in Israel. </p>
    <p> He also studied at the University of Maryland (Baltimore Campus), and at the Torah Institutions of Netzach Israel and Torah Ore, as well as the Mir Yeshiva. </p>
    <p>Rabbi Dovid Lipman now lives in Beitar Illit, Israel, where he is blessed to be able to enjoy much <em>nachas</em> from both children and grandchildren.</p>
    <p>(You can click on this <a href="OldTorahFiles/OldTorahFiles.htm">link to his old Torah files</a> which we have recently begun uploading.)</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Author'
}
</script>
