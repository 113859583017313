import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    topic: 'chanukah',
    registered: true // for now
  },
  mutations: {
    setTopic (state, topic) {
      state.topic = topic
    },
    setRegistered (state, registered) {
      state.registered = registered
    }
  },
  actions: {
    setTopic ({ commit }, topic) {
      commit('setTopic', topic)
    },
    setRegistered ({ commit }, registered) {
      commit('setRegistered', registered)
    }
  }
})
