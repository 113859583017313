<template>
  <table width="100%" border="0" cellspacing="0" cellpadding="0">
    <tr>
      <td>
        <a name="top"></a><a href="#" @click="goHome"><img :src="logo" width="314" height="90" border="0"></a>
      </td>
    </tr>
    <tr>
      <td bgcolor="#333366">
        <table border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td>
              <a class="menu" href="#" @click="goHome('chanukah')">Home</a>
            </td>
            <td>
              <a class="menu" href="#" @click="goHome('birkas_hachamah')">BH Home</a>
            </td>
            <td>
              <a class="menu" href="#" @click="$router.push('about')">About Halacha Sources</a>
            </td>
            <td>
              <a class="menu" href="#" @click="$router.push('author')">About the author</a>
            </td>
            <td>
              <a class="menu" href="#" @click="$router.push('links')">Links</a>
            </td>
            <td>
              <a class="menu"  href="mailto:feedback@LearnHalacha.com">Contact</a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    logo: String
  },
  methods: {
    goHome (newTopic) {
      if (typeof newTopic === 'string') {
        // console.log('going home with topic "' + newTopic + '"')
        this.$store.dispatch('setTopic', newTopic)
      }
      if (this.$route.name !== 'home') this.$router.push({ name: 'home' })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
