<template>
  <div class="about">
    <h1>About Halacha Sources </h1>
    <p>The <em>Halacha Sources</em> project is designed around two main objectives: </p>
    <ol>
      <li> To re-organize Halacha material around the rulings of the Shulchan Aruch (with Rema), the tracing of the Beis Yosef (and the Darkei Moshe and the Gra), and the finishing touches of the Mishnah Berurah (and some "supplementary" authorities). </li>
      <li> To present it all in English, clearly and understandably; but with as little "original interpretation" as possible, to enable studying as though from the source.</li>
    </ol>
    <p>The above should have clarified how this project intends to provide a more satisfactory tool for the study of this material. However, that does not truly express the deeper purpose of the project, which is to provide a new framework for serious Torah study. For generations, the almost exclusively Hebrew-Aramaic Torah texts were studied by all educated Jews, and they found in them depth, profundity, and challenge, and practicality. Today, many wish to participate in this most beautiful of pursuits, but feel unready - or even unable - to overcome the language barrier. They see before them as the only attainable option - English Judaica. Most of the available works lack the above-mentioned qualities of satisfying Torah study, for many people, and this project was conceived to fill that lack. </p>
    <p>We hope to compile more volumes (and to print them), probably beginning with the Halachos of Shabbos (and specifically, the Halachos of Friday &quot;day&quot; and &quot;Friday night&quot; first) and Purim.  Anyone interested in participating in the project, actively or through dedications etc., please click the &quot;Contact&quot; link, or just contact me by e-mailing <a href="mailto:feedback@learnhalacha.com">feedback@learnhalacha.com</a> (or otherwise).</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'About'
}
</script>
