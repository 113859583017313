<template>
  <div>
    <H1><A :href="'docs/HalachaSources-'
                    + (topic === 'chanukah' ? 'prerelease1' : 'BirkasHaChamah') + '.pdf'" 
           target=_blank>Halacha Sources: Halachos of {{ 
                          topic === 'chanukah' ? 'Chanukah pre-release' : 'Birkas HaChamah' }}</A></H1>
    <P>To view, click on any of these links. For FREE DOWNLOAD, right-click and 
       choose "Save target as"</P>
    <H2>Table of Contents</H2>
    <A name=intro></A>
    <P v-if="topic === 'chanukah'">
       <A href="docs/HalachaSources-prerelease1.pdf" 
          target=_blank>The complete text</A> (222 pages)<BR />
       <A href="docs/001HS_Chanukah_intro_1-7.pdf" 
          target=_blank>Introduction</A> (7 pages)<BR />
       <A href="docs/002HS_Chanukah_TOC_8-15.pdf" 
          target=_blank>Complete Table of Contents</A> (8 pages)<BR />
       <A href="docs/018HS_Chanukah_translations_179-208.pdf" 
          target=_blank>Translations of Central Quotations (more literally)</A> (30 pages)<BR />
       <A href="docs/019HS_Chanukah_glossary_209-214.pdf" 
          target=_blank>Glossaries (regular and 'Principles')</A> (6 pages)<BR />
       <A href="docs/020HS_Chanukah_bibliography_215-219.pdf" 
          target=_blank>Bibliography</A> (5 pages)<BR />
       <A href="docs/021HS_Chanukah_index_220-222.pdf" 
          target=_blank>Subject Index</A> (3 pages)
    </P>
    <P v-if="topic === 'birkas_hachamah'">
       <A href="docs/HalachaSources-BirkasHaChamah.pdf" 
          target=_blank>The complete text</A> (20 pages)<BR />
       <A href="docs/001HS_BirkasHaChamah_title_page.pdf" 
          target=_blank>Title page</A> (including table of contents)<BR />
       <A href="docs/006HS_BirkasHaChamah_translations_12-13.pdf" 
          target=_blank>Translations of Central Quotations (more literally)</A> (2 pages)<BR />
       <A href="docs/007HS_BirkasHaChamah_glossary_14-15.pdf" 
          target=_blank>Glossaries (regular and 'Principles')</A> (2 pages)<BR />
       <A href="docs/008HS_BirkasHaChamah_bibliography_16-17.pdf" 
          target=_blank>Bibliography</A> (2 pages)<BR />
       <A href="docs/009HS_BirkasHaChamah_appendix_18-19.pdf" 
          target=_blank>Appendix</A> (2 pages)
    </P>
    <P v-if="highlightsAndDirectoryImplemented">
      Click here for our special <A href="#" @click="$router.push({ name: 'highlights' })" target=_blank>"Highlights" articles</A>!<BR />
      Click here for our <A href="#" @click="$router.push({ name: 'directory' })" target=_blank>"Shulchan Aruch directory"</A>!<BR />
    </P>
    <sample :topic="topic" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Sample from '@/components/Sample.vue'

export default {
  name: 'Home',
  components: {
    Sample
  },
  data () {
    return {
      highlightsAndDirectoryImplemented: false
    }
  },
  computed: {
    ...mapState(['topic', 'registered'])
  }
}
</script>
