<template>
  <div>
    <h1><em>Halacha Sources</em><br>
    {{ allContent[topic].title }}</h1>
    <h2>Table of Contents</h2>
    <div v-for="(item, index) in allContent[topic].links" :key="'content-' + index">
      <p><a :href="'docs/' + item.href" target="_blank">{{ item.text }}</a> -- {{ item.description }}</p>
    </div>
    <div v-for="(otherTopic, OTindex) in otherTopics" :key="'other-topic-' + OTindex">
      <h3><a href="#" @click="goHome(otherTopic)">View The Volume On the {{ allContent[otherTopic].title }}</a></h3>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const allContent = {
  birkas_hachamah: {
    title: 'The Halachos of Birkas HaChamah',
    links: [
      {
        href: "HalachaSources-BirkasHaChamah.pdf",
        text: "\"TEKUFAS SHMUEL\"",
        description: "THE SIMPLIFIED SOLAR YEAR AND SEASONS - AN INTRODUCTION TO BIRKAS HACHAMAH"
      },
      {
        href: "HalachaSources-BirkasHaChamah.pdf",
        text: "WHEN IS THERE A BRACHA UPON SEEING THE SUN",
        description: "UNDER WHAT CIRCUMSTANCES IS IT SAID?"
      },
      {
        href: "HalachaSources-BirkasHaChamah.pdf",
        text: "SAYING THE BRACHA UPON \"SEEING\" THE SUN",
        description: "EXACTLY WHAT DOES THAT MEAN?"
      },
      {
        href: "HalachaSources-BirkasHaChamah.pdf",
        text: "THE TIME OF THE BRACHA UPON SEEING THE SUN",
        description: "EXACTLY WHEN IN THE DAY IS IT SAID?"
      },
      {
        href: "HalachaSources-BirkasHaChamah.pdf",
        text: "TEXT FOR THE BRACHA UPON SEEING THE SUN",
        description: "WHAT EXACTLY DOES ONE SAY?"
      }
    ]
  },
  chanukah: {
    title: 'The Halachos of Chanukah',
    links: [
      {
        href: "003HS_Chanukah_OrachChaim670_16-23.pdf",
        text: "Orach Chayim, 670",
        description: "Things that are Assur or Muttar on the Days of Chanukah"
      },
      {
        href: "004HS_Chanukah_OrachChaim671_24-57.pdf",
        text: "Orach Chayim, 671",
        description: "The Basic System of Chanukah Candles (and their location)"
      },
      {
        href: "005HS_Chanukah_OrachChaim672_58-70.pdf",
        text: "Orach Chayim, 672",
        description: "The Lighting Time for the Chanukah Candles"
      },
      {
        href: "006HS_Chanukah_OrachChaim673_71-88.pdf",
        text: "Orach Chayim, 673",
        description: "Oils and Wicks that are Valid for Chanukah"
      },
      {
        href: "007HS_Chanukah_OrachChaim674_89-95.pdf",
        text: "Orach Chayim, 674",
        description: "When is it Muttar to Light One Candle From Another?"
      },
      {
        href: "008HS_Chanukah_OrachChaim675_96-105.pdf",
        text: "Orach Chayim, 675",
        description: "The Lighting Makes the Mitzvah (not the setting in place)"
      },
      {
        href: "009HS_Chanukah_OrachChaim676_106-120.pdf",
        text: "Orach Chayim, 676",
        description: "The Order of the Brachos and the Lighting"
      },
      {
        href: "010HS_Chanukah_OrachChaim677_121-135.pdf",
        text: "Orach Chayim, 677",
        description: "The Halachos of a \"Guest\" concerning Chanukah Candles"
      },
      {
        href: "011HS_Chanukah_OrachChaim678_136-139.pdf",
        text: "Orach Chayim, 678",
        description: "Precedence of Shabbos Candles over Chanukah Candles"
      },
      {
        href: "012HS_Chanukah_OrachChaim679_140-143.pdf",
        text: "Orach Chayim, 679",
        description: "Chanukah Candle-lighting on the Eve of the Shabbos"
      },
      {
        href: "013HS_Chanukah_OrachChaim680_144-146.pdf",
        text: "Orach Chayim, 680",
        description: "Placing Candles Close to the Entrance (the night of Shabbos)"
      },
      {
        href: "014HS_Chanukah_OrachChaim681_147-151.pdf",
        text: "Orach Chayim, 681",
        description: "Using Chanukah Candles for Havdalah (and the Order)"
      },
      {
        href: "015HS_Chanukah_OrachChaim682_152-160.pdf",
        text: "Orach Chayim, 682",
        description: "The Halachos of \"Al HaNissim\" on Chanukah"
      },
      {
        href: "016HS_Chanukah_OrachChaim683_161-163.pdf",
        text: "Orach Chayim, 683",
        description: "Hallel is Completed on all Eight Days of Chanukah"
      },
      {
        href: "017HS_Chanukah_OrachChaim684_164-178.pdf",
        text: "Orach Chayim, 684",
        description: "The Order of the Torah Reading on Chanukah"
      }
    ]
  }
}

export default {
  name: 'Simanim',
  computed: {
    allContent () {
      return allContent
    },
    otherTopics () {
      return Object.keys(allContent).filter(k => k !== this.$store.state.topic)
    },
    ...mapState(['topic', 'registered'])
  },
  methods: {
    goHome (newTopic) {
      if (typeof newTopic === 'string') {
        // console.log('going home with topic "' + newTopic + '"')
        this.$store.dispatch('setTopic', newTopic)
      }
      if (this.$route.name !== 'home') this.$router.push({ name: 'home' })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
